.search_options__title {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14pt;
  padding: 0px 30px 0px;
}

.search_options__subtitle {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  color: #a5a5a5;
  padding: 0px 30px 0px;
}

.search__container {
  display: flex;
  padding: 0px 30px;
}

.search__container > div > input[type='radio'] {
  position: absolute;
  opacity: 0;

  + label {
    color: #989898;
    &:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      margin-right: 0.5rem;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
  }

  &:checked {
    + label {
      &:before {
        background-color: #3197ee;
        box-shadow: inset 0 0 0 4px #f4f4f4;
      }
    }
  }
}

.search__container > div:not(:first-child) {
  margin-left: 15px;
}

@media only screen and (max-width: 1400px) {
  .search__container {
    align-items: center;
    flex-direction: column;
  }

  .search__input {
    width: 80%;
  }

  .buttons__group {
    width: 80%;
    margin-top: 10px;
  }
}
