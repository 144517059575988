.login {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  background-color: #005a40;
  overflow: hidden;
  overflow-y: hidden;
}

.login__title {
  margin: 1rem;
  color: #333333;
  font-size: 30px;
  font-weight: 900;
}

.login__wrapper {
  background: #fff;
  display: flex;
  z-index: 4;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 16px, 32px, 16px, 32px;
  margin-left: 400px;
  /* margin-top: 200px; */
}

.login__container {
  margin-top: 20px;
}

.login__input {
  width: 300px;
}

.login__error {
  margin-bottom: 10px;
  color: #c2143e;
}

.toggle_div {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 1rem;
}
