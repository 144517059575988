@import '../../styles/colors.module.css';

:root {
  --dropdown-font-family: var(--title-font-family);

  --dropdown-input-border-color: var(--color-light-silver-100);
  --dropdown-input-border-radius: 3px;
  --dropdown-input-font-size: 14px;
  --dropdown-input-height: 37px;
  --dropdown-input-line-height: 42px;
  --dropdown-input-text-color: var(--color-light-chromium-200);

  --dropdown-color-disabled: var(--color-light-silver-100);

  --dropdown-icon-size: 30px;

  --dropdown-option-color: var(--color-custom-light-carbon-20);

  --dropdown-disabled-option-opacity: 0.6;
  --dropdown-disabled-option-background: none;

  --dropdown-option-selected-color: var(--color-light-carbon-200);

  --dropdown-label-color: var(--color-light-chromium-100);
  --dropdown-label-dropdown-font-size: 15pt;

  --dropdown-secondary-text-font-size: 12px;
}
