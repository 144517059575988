span {
  margin-right: 5px;
}

.spinner {
  animation: rotation 1.4s ease-in-out infinite;
}

.circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: turn 1.4s ease-in-out infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes turn {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.spinner:first-child {
  stroke: #a2a6a4;
  stroke-width: 5px;
}

.invisible {
  display: none;
}
