/* navIcon animations */

* {
  margin: 0;
  padding: 0;
}

.navIcon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: none;
  border: none;
}

.navIcon:focus {
  outline: none;
}

.navIcon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}

.navIcon span:nth-child(1) {
  top: 0;
}

.navIcon span:nth-child(2),
.navIcon span:nth-child(3) {
  top: 9px;
}

.navIcon span:nth-child(4) {
  top: 18px;
}

.navIcon.open {
  margin-left: 290px;
  position: fixed;
}

.navIcon.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.navIcon.open span:nth-child(2) {
  transform: rotate(45deg);
  background: #ffffff;
}

.navIcon.open span:nth-child(3) {
  transform: rotate(-45deg);
  background: #ffffff;
}

.navIcon.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}
