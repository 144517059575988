@import '../../styles/colors.module.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.generalWallboardCSI {
  background-color: var(--color-dark-background);
  color: var(--color-white);
  font-weight: bold;
  min-height: 100vh;
}

.captionWrapper {
  text-align: center;
}

.captionWrapper > div:not(:first-child) {
  padding-top: 1rem;
}

.captionWrapper > div > span {
  padding-right: 2rem;
}
