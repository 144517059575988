@import '../../styles/colors.module.css';

.generalWallboardCSI {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-background);
  color: var(--color-white);
  font-weight: bold;
  min-height: 100vh;
}

.filterWrapper {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 10px;
}

.filterWrapper > div {
  background-color: var(--color-dark-card-default-background);
  margin: 10px;
  padding: 20px 10px;
  border-radius: 5px;
  width: 100%;
}

.captionWrapper {
  text-align: center;
  padding-top: 1rem;
}

.captionWrapper > span {
  margin: 0 1rem;
}
