:root {
  /* WallBoard Color */
  --color-light-blue-100: #103a21;
  --color-dark-background: #0d131a;
  --color-dark-grey-light-font: #8295a6;
  --color-dark-card-header-background: #262c32;
  --color-dark-card-light-background: #414b55;
  --color-dark-card-default-background: #161d26;
  --color-light-green-wallboard: #94c83d;
  --color-green-wallboard: #13aa4c;
  --color-yellow-wallboard: #f1aa00;
  --color-red-wallboard: #e64b28;
  --color-alice-blue: #f0f8ff;
  /* Pagarme Colors */
  --color-light-orange-100: #ffa317;
  --color-light-orange-50: #ffd18b;
  --color-light-orange-20: #ffedd1;
  --color-light-red-100: #c2143e;
  --color-light-red-50: #e0899e;
  --color-light-red-20: #f3d0d8;
  --color-light-purple-50: #ca99e4;
  --color-light-purple-20: #ead6f4;
  --color-light-blue-50: #9cdee8;
  --color-light-blue-20: #d8f2f6;
  --color-light-green-100: #38be6a;
  --color-light-green-50: #9bdeb4;
  --color-light-green-20: #d7f2e1;
  --color-light-salmon-100: #ff796f;
  --color-light-salmon-120: #dc6860;
  --color-light-salmon-150: #ba5851;
  --color-light-greenish-120: #32b88b;
  --color-light-greenish-100: #37cc9a;
  --color-light-silver-40: #eeeeee;
  --color-light-silver-20: #f6f6f6;
  --color-light-coal-100: #454545;
  --color-light-steel-100: #757575;
  --color-light-chromium-100: #a0a0a0;
  --color-light-chromium-50: #cfcfcf;
  --color-light-steel-50: #bababa;
  --color-light-coal-50: #a2a2a2;
  --color-white: #ffffff;
  --color-light-silver-50: #e9e9e9;
  --color-light-silver-100: #d4d4d4;
  --color-light-platinum-100: #c2cbd2;
  --color-light-platinum-50: #e0e5e8;
  --color-light-platinum-20: #f3f5f6;
  --color-light-iron-100: #a4acb2;
  --color-light-iron-50: #d1d5d8;
  --color-light-iron-20: #edeef0;
  --color-light-grey-100: #858c8f;
  --color-light-grey-50: #c2c5c7;
  --color-light-grey-20: #e7e8e9;
  --color-light-smoke-100: #e0e0e0;
  --color-light-smoke-50: #efefef;
  --color-black: #000000;
  --color-light-smoke-40: #f3f3f3;
  --color-light-greenish-150: #2b9e77;
  --color-light-greenish-20: #c3f0e0;
  --color-light-salmon-20: #ffc9c5;
  --color-light-salmon-50: #ffa19a;
  --color-light-greenish-50: #73dbb8;
  --color-light-purple-100: #8459b9;
  --color-light-steel-200: #5c5c5c;
  --color-light-carbon-200: #050505;
  --color-light-carbon-100: #1f1f1f;
  --color-light-carbon-50: #2c2c2c;
  --color-light-carbon-40: #383838;

  --color-custom-light-carbon-20: #686868;

  --color-light-warning: var(--color-light-purple-100);
  --color-light-error: var(--color-light-red-100);
  --color-light-info: var(--color-light-orange-100);
  --color-light-success: var(--color-light-blue-100);

  --color-light-greenish-gradient: linear-gradient(
    var(--gradient-angle),
    var(--color-light-greenish-100) 10%,
    #45babf 90%
  );
  --color-light-salmon-gradient: linear-gradient(
    var(--gradient-angle),
    #fc8a5d 10%,
    var(--color-light-salmon-100) 90%
  );
}
