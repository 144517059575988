@import './properties';
@import '../../styles/typography.module.css';

.switch {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding-left: calc(var(--switch-width) + var(--switch-label-left));
  height: var(--switch-height);
  align-items: center;
  font-family: var(--switch-label-font-family);
}

.switch,
.switch:before,
.switch:after {
  box-sizing: border-box;
}

.switch:before,
.switch:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--switch-radius);
}

.switch:before {
  background-color: var(--switch-bullet-off-color);
  width: var(--switch-bullet-width);
  height: var(--switch-bullet-height);
  z-index: 1;
  transform: translateX(var(--switch-bullet-off)) translateY(calc(var(--switch-bullet-height) / 2));
  transition: transform 100ms ease-in;
}

.switch:after {
  background-color: var(--switch-bullet-off-background);
  width: var(--switch-width);
  height: var(--switch-height);
  z-index: 0;
  border: 1px solid var(--switch-border-color);
  transition: background-color 70ms ease-in;
}

.checked:before {
  background-color: var(--switch-bullet-on-color);
  transform: translateX(var(--switch-bullet-on)) translateY(calc(var(--switch-bullet-height) / 2));
}

.checked:after {
  background-color: var(--switch-bullet-on-background);
  border-color: var(--switch-bullet-on-background);
}

.checked {
  color: var(--switch-bullet-on-background);
}

.disabled {
  color: var(--switch-disabled-color);
}

.disabled:before {
  background-color: var(--switch-disabled-color);
}

.disabled:after {
  background-color: var(--switch-disabled-background);
  border-color: var(--switch-disabled-color);
}

.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

.switch span::selection {
  color: inherit;
}

.checked span::selection {
  color: var(--switch-bullet-on-background);
}
