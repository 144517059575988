@import '../../styles/colors.module.css';

.container {
  background-color: var(--color-dark-background);
  color: var(--color-white);
  font-weight: bold;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;
}

.profileGroupContainer {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}

.profileGroupDivider {
  background-color: var(--color-dark-card-header-background);
}
