.search_options__title {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14pt;
  padding: 0px 30px 0px;
}

.search_options__subtitle {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  color: #a5a5a5;
  padding: 0px 30px 0px;
}

.search__input {
  font-family: 'Roboto', sans-serif;

  width: 480px;
  height: 50px;

  background: #262c32;
  color: #989898;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-left: 14px;
}

.search__input:focus {
  outline: none;
}

.search__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px;
}

.buttons__group {
  width: 800px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1400px) {
  .search__container {
    align-items: center;
    flex-direction: column;
  }

  .search__input {
    width: 80%;
  }

  .buttons__group {
    width: 80%;
    margin-top: 10px;
  }
}
