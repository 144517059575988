@import '../../styles/colors.module.css';

:root {
  --switch-label-left: 15px;
  --switch-label-font-family: var(--title-font-family);

  --switch-width: 40px;
  --switch-height: 21px;
  --switch-radius: 10px;
  --switch-bullet-width: 11px;
  --switch-bullet-height: 11px;
  --switch-bullet-off: 5px;
  --switch-bullet-on: calc(var(--switch-width) - 16px);

  --switch-border-color: var(--color-light-chromium-100);
  --switch-border-color-dark: var(--color-dark-grey-light-font);
  --switch-bullet-on-color: var(--color-white);
  --switch-bullet-on-color-dark: var(--color-dark-card-default-background);
  --switch-bullet-off-color: var(--color-light-greenish-100);
  --switch-bullet-off-color-dark: var(--color-white);
  --switch-bullet-on-background: var(--color-light-greenish-100);
  --switch-bullet-off-background: var(--color-white);
  --switch-bullet-off-background-dark: var(--color-dark-card-default-background);
  --switch-disabled-color: var(--color-light-chromium-100);
  --switch-disabled-background: var(--color-white);
  --switch-disabled-background-dark: var(--color-dark-card-default-background);
}
