@import 'colors.module.css';
@import 'spacing.module.css';
@import 'typography.module.css';

/* stylelint-disable */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
