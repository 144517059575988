@font-face {
  font-family: 'Assistant';
  src: url('./assistant-bold.woff2') format('woff2'), url('./assistant-bold.woff') format('woff');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('./assistant-regular.woff2') format('woff2'),
    url('./assistant-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
