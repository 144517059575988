.dowloadButton {
  background: green;
  border: round;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  margin: 20px;
}

.dowloadButton:disabled,
.dowloadButton[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
