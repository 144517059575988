/* stylelint-disable selector-max-class */
@import '../../styles/typography.module.css';
@import './properties.module.css';

.container * {
  box-sizing: border-box;
  font-family: var(--dropdown-font-family);
  letter-spacing: var(--letter-spacing);
}

.container select {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
}

.container select:disabled {
  cursor: not-allowed;
}

.input {
  border: 0;
  background: transparent;
  border-bottom: 2px solid var(--dropdown-input-border-color);
  border-radius: var(--dropdown-input-border-radius);
  outline: 0;
  display: block;
  text-align: left;
  font-size: var(--dropdown-input-font-size);
  width: 100%;
  height: var(--dropdown-input-height);
  cursor: pointer;
  color: var(--dropdown-input-text-color);
  text-decoration: none;
  opacity: 1;
  line-height: var(--dropdown-input-line-height);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--dropdown-icon-size);
}

.input > a {
  display: block;
}

.buttonGroup {
  position: relative;
  width: 100%;
}

.option {
  color: var(--dropdown-option-color);
}

.disabledOption {
  opacity: var(--dropdown-disabled-option-opacity);
}

.disabledOption:hover {
  background: var(--dropdown-disabled-option-background);
  cursor: not-allowed;
}

.isSelected {
  color: var(--dropdown-option-selected-color);
}

.label {
  color: var(--dropdown-label-color);
  font-size: var(--dropdown-label-dropdown-font-size);
  display: block;
  margin-bottom: -7px;
  font-weight: 500;
}

.secondaryText {
  width: 100%;
  font-size: var(--dropdown-secondary-text-font-size);
  text-align: left;
  margin: 0;
  margin-top: 8px;
}

.arrow {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  right: 0;
  width: var(--dropdown-icon-size);
  height: var(--dropdown-icon-size);
}

.containerDisabled {
  cursor: not-allowed;
}

.containerDisabled .input {
  border-bottom: 1px dashed var(--dropdown-color-disabled);
  color: var(--dropdown-color-disabled);
}

.containerDisabled .label {
  color: var(--dropdown-color-disabled);
  cursor: not-allowed;
}

.containerError .arrow,
.containerSuccess .arrow {
  bottom: 24px;
}

.containerError .input {
  border-bottom: 2px solid var(--color-light-error);
}

.containerError .label {
  color: var(--color-light-error);
}

.containerError .secondaryText {
  color: var(--color-light-error);
}

.containerSuccess .input {
  border-bottom: 2px solid var(--color-light-success);
}

.containerSuccess .label {
  color: var(--color-light-success);
}

.containerSuccess .secondaryText {
  color: var(--color-light-success);
}
