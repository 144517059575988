.cardHeader {
  background-color: #103a21;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.tableDiv {
  text-align: center;
}
