@import '../../styles/colors.module.css';
@import '../../styles/spacing.module.css';
@import '../../styles/typography.module.css';

.sidebar {
  background-color: #103a21;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  width: 299px;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0 20px;
  transition: all 0.5s ease-in-out;
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.sidebar.closed {
  margin-left: -200px;
  background-color: var(--color-white);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  display: none;
}

.sidebar__content {
  font-size: 20px;
  margin-top: 32px;
}

.side-bar__ul {
  padding: 0 24px;
}

.side-bar__li {
  list-style: unset !important;
}

.sidebar__content a,
.sidebar__content label,
.sidebar__content div,
.sidebar__content ul,
.sidebar__content select {
  color: var(--color-white);
  line-height: 40px;
  margin-bottom: 20px;
  font-size: 20px;
}

.sidebar__content > div {
  margin-bottom: 20px;
}

.side-bar__header {
  width: 100%;
  height: 43px;
  text-align: center;
  background-color: #103a21;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.side-bar__logout {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.side-bar__logout:hover {
  text-decoration: underline;
}

.invisible {
  display: none;
}
