.clock {
  height: 43px;
  position: absolute;
  top: 0px;
  right: 10px;

  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}
