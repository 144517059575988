@import '../../styles/colors.module.css';

:root {
  --cardContentItemDefault: {
    background-color: var(--color-dark-card-default-background);
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
    height: 21vh;
    text-align: center;
    color: var(--color-dark-grey-light-font);
    justify-content: center;
    background-clip: content-box;
    border: 1px solid var(--color-dark-card-header-background);
  }
}

.green {
  color: var(--color-green-wallboard);
}

.yellow {
  color: var(--color-yellow-wallboard);
}

.red {
  color: var(--color-red-wallboard);
}

.white {
  color: var(--color-white);
}

.orange {
  color: var(--color-light-orange-100);
}

.card {
  padding: 30px 30px 0;
}

.smallCard {
  padding: 20px 20px 0;
}

@media screen and (max-width: 1100px) {
  .smallCard {
    font-size: 0.8rem;
    padding: 5px;
  }
}

.cardHeader {
  background-color: var(--color-dark-card-header-background);
  font-size: 15px;
  text-align: center;
  padding: 25px 0;
  border-radius: 4px 4px 0 0;
}

.cardAside {
  padding: 25px 25px 0px 55px;
}

.smallCardAside {
  padding: 25px 25px 0px 55px;
}

.cardHeaderAsideUnified,
.cardHeaderAside {
  max-width: 40vh;
}

.phoneCardHeaderAsideUnified {
  max-width: 24vh;
}

.cardHeaderAsideUnified,
.cardHeaderAside,
.phoneCardHeaderAsideUnified {
  background-color: var(--color-dark-card-header-background);
  font-size: 13px;
  position: absolute;
  transform-origin: 0 0;
  font-size: 13px;
  color: var(--color-white);
  margin: 0 0 10px 0;
  padding: 5px 1vh;
  border: 0.9px solid #262c32;
  border-radius: 0px 0px 2px 2px;
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
  -moz-transform: rotate(90deg); /* Firefox */
}

.cardHeaderAside {
  width: 21vh;
  max-width: 21vh;
}

.titleIcon {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 25px;
}

.cardHeaderRed {
  background-color: var(--color-red-wallboard);
  font-size: 15px;
  text-align: center;
  padding: 25px 0;
  border-radius: 4px 4px 0 0;
}

.cardHeaderGreen {
  background-color: var(--color-green-wallboard);
  font-size: 15px;
  text-align: center;
  padding: 25px 0;
  border-radius: 4px 4px 0 0;
}

.cardHeaderPurple {
  background-color: var(--color-light-warning);
  font-size: 15px;
  text-align: center;
  padding: 25px 0;
  border-radius: 4px 4px 0 0;
}

.cardHeaderTitle {
  text-transform: uppercase;
  display: flex;
}

.cardContent {
  display: flex;
  justify-content: stretch;
}

.cardContentItems {
  display: inline-flex;
}

.cardContentItemsVertical {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  display: inline-flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
}

.cardContentItemsVertical div:last-child {
  border-radius: 0 0 4px 4px;
}

.cardContentItems div:first-child {
  border-radius: 0 0 0 4px;
}

.cardContentItemsVerticalBig {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  display: inline-flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  height: unset;
}

.cardContentItemsAside {
  display: inline-flex;
}

.cardContentItemsVerticalAside {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  display: inline-flex;
  flex-direction: column;
  border-radius: 0 0 4px 0px;
}

.cardContentItemsVerticalAside div:last-child {
  border-radius: 0 0 4px 0px;
}

.cardContentItemsAside div:first-child {
  border-radius: 0 0 0 0px;
}

.cardContentItemsVerticalBigAside {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  display: inline-flex;
  flex-direction: column;
  border-radius: 0 0 4px 0px;
  height: unset;
}

.tableRow {
  width: 100%;
  display: block;
  background-clip: content-box;
}

.tableRowUnified {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-clip: content-box;
}

.cardContentItem {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);
}

.cardContentItemLightGrey {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  background-color: var(--color-dark-card-light-background);
  color: var(--color-white);
}

.cardContentTitle {
  text-transform: uppercase;
  font-size: 15px;
}

.cardContentTitleWithDivider {
  text-transform: uppercase;
  font-size: 15px;
  padding: 0px;
}

.cardContentWithDividerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
}

.cardContentData {
  font-size: 70px;
  width: 100%;
  padding-top: 10px;
  font-weight: normal;
}

.cardContentDataMin {
  font-size: 45px;
  width: 100%;
  font-weight: normal;
}

.cardContentDataMax {
  font-size: 40px;
  width: 100%;
  padding-top: 10px;
  font-weight: normal;
}

.cardContentItemVertical {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  flex-direction: row;
}

.cardContentItemVertical .cardContentTitle {
  text-align: left;
  padding: 0 0 0 10px;
  font-size: 14px;
  width: 155%;
}

.cardContentItemVertical .cardContentData {
  text-align: right;
  font-size: 35px;
  padding: 0 10px 0 0;
}

.cardContentItemVerticalBig {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  flex-direction: row;
  height: 30px;
}

.cardContentItemVerticalBig .cardContentTitle {
  text-align: center;
  font-size: 16px;
  width: 100%;
  color: white;
}

.cardContentItemVerticalBig .cardContentData {
  width: 0;
}

.cardContentItemMini {
  background-color: var(--color-dark-background);
  width: 25%;
  display: inline-block;
  padding: 3.5px 0;
}

.cardContentItemMini .cardContentTitle {
  text-transform: uppercase;
  font-size: 12px;
}

.cardContentItemMini .cardContentData {
  text-transform: uppercase;
  font-size: 35px;
  color: var(--color-white);
  font-weight: normal;
}

.phoneCardContentData {
  font-size: 30px;
  color: var(--color-white);
  font-weight: normal;
}

.cardContentItemTable {
  background-color: var(--color-dark-card-default-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 21vh;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);

  justify-content: center;
  background-color: var(--color-dark-background);
  width: 25%;
  border-radius: 0 0 4px;
}

.cardContentItemTableUnified {
  height: 40vh;
}
.smallCardContentItemTableUnified {
  height: 24vh;
}

.cardContentItemTableUnified,
.smallCardContentItemTableUnified {
  background-color: var(--color-dark-background);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: var(--color-dark-grey-light-font);
  justify-content: center;
  background-clip: content-box;
  border: 1px solid var(--color-dark-card-header-background);
  border-radius: 0 0 4px;
}

.tableRow:last-child .cardContentItemMini:last-child {
  border-radius: 0 0 4px;
}

.invisible {
  display: none;
}

.wallboardTableContainer {
  background-color: var(--color-dark-card-header-background);
  border-radius: 4px 4px 0 0;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
}

.wallboardTableRow {
  background-color: var(--color-dark-background);
}

.wallboardTableRow:nth-child(odd) {
  background-color: var(--color-light-carbon-100);
}

.wallboardTableHeader {
  background-color: var(--color-dark-card-header-background);
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: 5px;
}

.wallboardTableHeader > thead > tr > th {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.wallboardTableNumberColumn {
  min-width: 2rem;
  text-align: center;
}

.wallboardTableLongColumn {
  word-break: break-word;
  padding: 0 1rem 0 0.5rem;
}

.wallboardTableSortButton {
  color: #fff;
  background: transparent;
  border: unset;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 900;
  font-family: 'Roboto' sans-serif;
}

.wallboardTableSortButton:focus {
  outline: none;
}

.ball {
  border-radius: 50%;
  display: inline-block;
  height: 11px;
  width: 11px;
  overflow: hidden;
}

.titleWithSwitch {
  display: flex;
  align-items: center;
  width: 42vw !important;
  justify-content: space-between;
}

.backgroundGray {
  background-color: var(--color-light-steel-100);
}

.backgroundGreen {
  background-color: var(--color-green-wallboard);
}

.backgroundRed {
  background-color: var(--color-red-wallboard);
}

.backgroundOrange {
  background-color: var(--color-light-orange-100);
}

.backgroundYellow {
  background-color: var(--color-light-orange-50);
}

.wallboardBadge {
  margin: 5px;
}

.badgeChat {
  background: var(--color-light-purple-100);
}

.badgeWhatsapp {
  background: var(--color-green-wallboard);
}

.badgeVoice {
  background: var(--color-light-red-100);
}

.badgeEmail {
  background: var(--color-yellow-wallboard);
}

.badgeEscritos {
  background: var(--color-light-steel-200);
}

@media screen and (max-width: 1700px) {
  .cardContentItemMini .cardContentData,
  .phoneCardContentData {
    font-size: 28px;
  }
}

@media screen and (max-width: 1600px) {
  .cardContentItemMini .cardContentData,
  .phoneCardContentData {
    font-size: 20px;
  }
}
