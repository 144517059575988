@import '../../styles/spacing.module.css';
@import '../../styles/colors.module.css';

:root {
  --carousellPaginationButtonDefault: {
    background: none;
    border: none;
    color: var(--color-light-green-wallboard);
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
  }
}

.carousell {
  height: 21vh;
  border: 1px solid var(--color-dark-card-header-background);
  border-radius: 0 0 4px 4px;
  background-color: var(--color-dark-card-default-background);
}

.carousellContents {
  text-align: center;
  height: 13vh;
}

.carousellPagination {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-small);
  text-transform: uppercase;
}

.carousellPaginationButton {
  background: none;
  border: none;
  color: var(--color-light-green-wallboard);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}

.carousellPaginationButton:focus {
  outline: none;
}

.carrousellPagesInfo {
  padding: 10px;
  font-size: 14px;
  color: var(--color-dark-grey-light-font);
}

.carousellPagesInfoSelectedpage {
  color: var(--color-light-green-wallboard);
}

.carousellTitle {
  color: var(--color-dark-grey-light-font);
  padding: 20px;
}

.carousellContent {
  color: var(--color-white);
  padding: 0 15px;
  height: 9vh;
  font-weight: normal;
}
