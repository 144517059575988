@import url('https://fonts.googleapis.com/css?family=RobotoDraft:500');

.is-loading {
  position: absolute;
  z-index: 5;
  background-color: #ffffff;
  opacity: 0.8;
  height: 100vh;
  width: 100%;
  font-family: 'RobotoDraft', sans-serif;
  font-size: 35px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.is-loading .spinner {
  margin-left: -40px;
  animation: rotation 1.4s ease-in-out infinite;
}

.is-loading .circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: turn 1.4s ease-in-out infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes turn {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.is-loading .spinner:first-child {
  stroke: #103a21;
  stroke-width: 5px;
}

.invisible {
  display: none;
}
