@import '../../styles/colors.module.css';

.wallboardTableContainer {
  background-color: var(--color-dark-card-header-background);
  border-radius: 4px 4px 0 0;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
}

.wallboardTableRow {
  background-color: var(--color-dark-background);
}

.wallboardTableRow:nth-child(odd) {
  background-color: var(--color-light-carbon-100);
}

.wallboardTableHeader {
  background-color: var(--color-dark-card-header-background);
  border: 2px solid var(--color-dark-card-header-background);
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.wallboardTableColumn {
  padding: 5px;
  text-align: center !important;
  font-size: 14pt;
}

.wallboardTableHeaderColumn {
  color: #fff;
  text-transform: uppercase;
  text-align: center !important;
  padding: 5px;
}

.wallboardTableNumberColumn {
  width: 2rem;
  display: flex;
  justify-content: center;
}

.tableRow:last-child .cardContentItemMini:last-child {
  border-radius: 0 0 4px;
}
