@import '../../styles/colors.module.css';

.generalWallboard {
  background-color: var(--color-dark-background);
  color: var(--color-white);
  font-weight: bold;
  height: 100vh;
}

.generalWallboardCaptionContainer {
  padding-top: 10px;
}

.generalWallboardCaptionContainer > span {
  margin-left: 20px;
}
